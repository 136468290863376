import React, { useEffect, useState } from "react"

import { Link, navigate } from "gatsby"
import { Navbar, Container, Col, Row, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { Helmet } from "react-helmet"
import * as styles from "./login.module.css"
import * as globalCss from "../components/styles/global.module.css"

import useAuth from "../hooks/useAuth"
import Header from "../components/Header"

const Logout = () => {
  const { logout, isLoading, isAuthenticated } = useAuth()

  // Check once on load
  useEffect(() => {
    logout()
  }, [])

  // useEffect(() => {
  //   if (!isAuthenticated && !isLoading) {
  //     setTimeout(function () {
  //       logout()
  //     }, 1000);
  //     setTimeout(function () {
  //       if (typeof window !== 'undefined') {
  //         window.location.replace('/mission');
  //       }
  //     }, 2000);
  //   }
  // }, [isAuthenticated, isLoading])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Menyiapkan Halaman ...</title>
      </Helmet>

      <Container className={styles.section}>
        <Row>
          <Col>
            <h1>Harap Tunggu Sebentar</h1>
            <div className={styles.login}>
              <FontAwesomeIcon icon={faCircleNotch} className={globalCss.spin} />

              <div>Sedang Mempersiapkan Halaman...</div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Logout
